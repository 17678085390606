import React, { Suspense, useCallback, useContext, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import SessionsEditTabsPanel from './SessionsEditTabsPanel';
import FieldList from '@dex/bubl-dash/src/lib/components/Fields/FieldList';
import FieldPopup from '@dex/bubl-dash/src/lib/components/Fields/FieldPopup';
import SessionsEditTabsBikeDetails from '../Bike/SessionsEditTabsBikeDetails';
import SessionsEditTabsBikePosture from '../Bike/SessionsEditTabsBikePosture';
import SessionsEditTabsBikePressure from '../Bike/SessionsEditTabsBikePressure';
import SessionsEditTabsBikeSetup from '../Bike/SessionsEditTabsBikeSetup';
import SessionsEditTabsBikeAdjustments from '../Bike/SessionsEditTabsBikeAdjustments';
import SessionsEditTabsBikePictures from '../Bike/SessionsEditTabsBikePictures';
import SessionsEditTabsBikeSummary from '../Bike/SessionsEditTabsBikeSummary';
import SessionsEditTabsBikeLeomo from '../Bike/SessionsEditTabsBikeLeomo';
import SessionsEditTabsBikeFinal from '../Bike/SessionsEditTabsBikeFinal';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import { bikeLabel } from '../../../functions/sharedBikeFit';
import EditableContext from '../../../context/EditableContext';
import { get, set } from '@dex/bubl-helpers';
import { useApp } from '@dex/bubl-dash';

const SessionsEditTabsBikeRetul = React.lazy(() => import('../Bike/SessionsEditTabsBikeRetul'));

const SessionsEditTabsBike: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, data } = props;

    return useMemo(() => (

        <SessionsEditTabsPanel
            heading={"Bikes"}
            {...props}
        >

            <Row gutter={8} edge={true}>

                <Col col={{ xs: 24, md: 24 }}>

                    <FieldList
                        form={form}
                        name={`bikes`}
                        label={"Bikes"}
                        addButtonLabel={"Add Bike"}
                        defaultValues={{
                        }}
                    >

                        {(form.values.bikes || []).map((bike, i) => (

                            <SessionsEditTabsBikeForm
                                key={i}
                                i={i}
                                bike={bike}
                                form={form}
                                data={data}
                            />

                        ))}

                    </FieldList>

                </Col>

            </Row>

        </SessionsEditTabsPanel>


    ), [props]);

}

const SessionsEditTabsBikeForm: React.FC<any> = (props: any) => {

    const { i, bike, form, data } = props;

    const prefix = `bikes[${i}]`;

    const [key, setKey] = useState(0);

    const [tab, setTab] = useState("details");

    const handleCopy = useCallback((handleClose) => {

        const items = form.getValue('bikes');

        handleClose();

        items.push(structuredClone(bike));

        form.handleChange({ name: 'bikes', value: items });

        form.setKey();

    }, [bike]);

    const upKey = useCallback(() => {

        setKey(key + 1);

    }, [key]);

    const editable = useContext(EditableContext);

    const app = useApp();

    const [settings] = app.store("appSettings");
    const useDefaultText = useCallback((defaultName, name: string) => {

        let text = get(settings.fit, defaultName);

        const values = form.values;

        set(values, name, text);

        setKey(key + 1);

    }, [props, form.hash]);

    return (

        <FieldPopup
            buttonLabel={"Bike " + (i + 1) + " - " + bikeLabel(bike)}
            heading={"Bike " + (i + 1) + " - " + bikeLabel(bike)}
            form={form}
            name={prefix}
            modalProps={{
                className: 'modal-top'
            }}
            actionButtons={(handleClose) => (

                <Button
                    label="Copy To New Bike"
                    size="small"
                    type={"faded"}
                    onClick={handleCopy.bind(null, handleClose)}
                />

            )}
            panelProps={{
                tabs: [
                    { label: "Details", name: "details" },
                    bike.type ? { label: "Setup", name: "setup" } : null,
                    bike.type ? { label: "Posture", name: "posture" } : null,
                    bike.type ? { label: "Adjustments", name: "adjustments" } : null,
                    bike.type ? { label: "RETUL - Posture", name: "retul" } : null,
                    bike.type ? { label: "RETUL - Bike", name: "final" } : null,
                    bike.type ? { label: "Pictures", name: "pictures" } : null,
                    bike.type ? { label: "Pressure", name: "pressure" } : null,
                    bike.type ? { label: "Leomo", name: "leomo" } : null,
                    bike.type ? { label: "Suitability", name: "summary" } : null,
                ].filter(Boolean),
                setTab: setTab,
                activeTab: tab
            }}
        >

            <div key={key} className={!editable ? 'form-not-editable' : ''}>

                <div className={tab === "details" ? "" : "hidden"}>

                    <SessionsEditTabsBikeDetails
                        i={i}
                        form={form}
                        data={data}
                        bike={bike}
                        prefix={prefix}
                        setKey={upKey}
                    />

                </div>

                <div className={tab === "setup" ? "" : "hidden"}>

                    <SessionsEditTabsBikeSetup
                        i={i}
                        form={form}
                        data={data}
                        bike={bike}
                        prefix={prefix}
                        setKey={upKey}
                    />

                </div>

                <div className={tab === "posture" ? "" : "hidden"}>

                    <SessionsEditTabsBikePosture
                        i={i}
                        form={form}
                        data={data}
                        bike={bike}
                        prefix={prefix}
                        setKey={upKey}
                    />

                </div>

                <div className={tab === "retul" ? "" : "hidden"}>

                    <Suspense fallback={<></>}>
                        <SessionsEditTabsBikeRetul
                            i={i}
                            form={form}
                            data={data}
                            bike={bike}
                            prefix={prefix}
                            setKey={upKey}
                        />
                    </Suspense>

                </div>

                <div className={tab === "adjustments" ? "" : "hidden"}>

                    <SessionsEditTabsBikeAdjustments
                        i={i}
                        form={form}
                        data={data}
                        bike={bike}
                        prefix={prefix}
                        setKey={upKey}
                        useDefaultText={useDefaultText}
                    />

                </div>

                <div className={tab === "final" ? "" : "hidden"}>

                    <SessionsEditTabsBikeFinal
                        i={i}
                        form={form}
                        data={data}
                        bike={bike}
                        prefix={prefix}
                        setKey={upKey}
                    />

                </div>

                <div className={tab === "pressure" ? "" : "hidden"}>

                    <SessionsEditTabsBikePressure
                        i={i}
                        form={form}
                        data={data}
                        bike={bike}
                        prefix={prefix}
                        setKey={upKey}
                        useDefaultText={useDefaultText}
                    />

                </div>

                <div className={tab === "pictures" ? "" : "hidden"}>

                    <SessionsEditTabsBikePictures
                        i={i}
                        form={form}
                        data={data}
                        bike={bike}
                        prefix={prefix}
                        setKey={upKey}
                    />

                </div>

                <div className={tab === "leomo" ? "" : "hidden"}>

                    <SessionsEditTabsBikeLeomo
                        i={i}
                        form={form}
                        data={data}
                        bike={bike}
                        prefix={prefix}
                        setKey={upKey}
                    />

                </div>

                <div className={tab === "summary" ? "" : "hidden"}>

                    <SessionsEditTabsBikeSummary
                        i={i}
                        form={form}
                        data={data}
                        bike={bike}
                        prefix={prefix}
                        setKey={upKey}
                        useDefaultText={useDefaultText}
                    />

                </div>

            </div>

        </FieldPopup>

    )

}

export default SessionsEditTabsBike;
