import { Col, Line, Row, Space } from '@dex/bubl-dash';
import React, { useMemo } from 'react';
import ReportMetaTable from '../../../elements/ReportMetaTable/ReportMetaTable';
import styles from "./SessionsReportRiderInformation.module.scss";
import ReportHeading from '../../../elements/ReportHeading/ReportHeading';
import CoachingReportHeading from '../../../elements/CoachingReportHeading/CoachingReportHeading';

const SessionsReportRiderInformation: React.FC<any> = (props: any) => {

    const { data, form } = props;

    let gender = data.athlete?.gender;

    let mileageCss;

    switch (data.cyclingMileagePerWeek) {
        case "Elite (>501KM)":
            mileageCss = "elite"
            break;
        case "Very High (401-500KM)":
            mileageCss = "very-high"
            break;
        case "High (301-400KM)":
            mileageCss = "high"
            break;
        case "Moderately-High (201-300KM)":
            mileageCss = "moderately-high"
            break;
        case "Moderate (101-200KM)":
            mileageCss = "noderate"
            break;
        case "Low (50-100KM)":
            mileageCss = "low"
            break;
        case "Very Low (<50KM)":
            mileageCss = "very-low"
            break;

        default:
            mileageCss = "none"
            break;
    }

    return useMemo(() => (

        <Row gutter={20} edge>

            <Col col={{ xs: 14 }}>

                <CoachingReportHeading
                    text="General"
                />

                <Row gutter={8} edge>

                    <Col col={{ xs: 14 }}>

                        <ReportMetaTable
                            data={data}
                            columnSize={{ xs: 12 }}
                            columnA={[
                                { label: "Athlete", key: "athlete.fullName" },
                                { label: "Main Sport", key: "athlete.mainSport" },
                                { label: "Riding Years", key: "yearsRiding", format: "number", suffix: " years" },
                                { label: "Riding Level", key: "cyclistLevel" },
                                { label: "Competes?", key: "competes" },
                            ]}
                            columnB={[
                                { label: "Gender", key: "athlete.gender" },
                                { label: "Age", key: "athlete.age" },
                                { label: "Height", key: "bio.height", suffix: " cm", format: "number" },
                                { label: "Weight", key: "bio.weight", suffix: " kg", format: "number" },
                                { label: "FTP (Current)", key: "bio.ftpW", suffix: " W", format: "number" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 10 }}>
                        <div className={styles.chartContainer}>

                            <ReportHeading
                                text={"Weekly Cycling Mileage"}
                            />

                            <div className={styles.barChart}>
                                <table>
                                    <tr>
                                        <td>Elite</td>

                                        <td>{'>'}501 km</td>
                                    </tr>
                                    <tr>
                                        <td>Very High</td>

                                        <td>201-500 km</td>
                                    </tr>
                                    <tr>
                                        <td>High</td>

                                        <td>301-400 km</td>
                                    </tr>
                                    <tr>
                                        <td>Moderately High</td>

                                        <td>201-300 km</td>
                                    </tr>
                                    <tr>
                                        <td>Moderate</td>

                                        <td>101-200 km</td>
                                    </tr>
                                    <tr>
                                        <td>Low</td>

                                        <td>50-100 km</td>
                                    </tr>
                                    <tr>
                                        <td>Very Low</td>

                                        <td> &lt;50 km </td>
                                    </tr>
                                </table>

                                <label
                                    className={[styles.mileageBar, styles[`${mileageCss}`]].join("")}
                                />

                            </div>

                            <div className={styles.label}>
                                You
                            </div>

                        </div>
                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 10 }}>

                <CoachingReportHeading
                    text="Total Frontal Surface (FSA)"
                />

            </Col>

            <Col col={{ xs: 14 }}>

                <CoachingReportHeading
                    text="Event"
                />

                <Row gutter={8} edge>

                    <Col col={{ xs: 8 }}>
                        <ReportMetaTable
                            data={data}
                            columnSize={{ xs: 12 }}
                            columnA={[
                                { label: "Event", key: "bio.eventType" },
                            ]}
                            columnB={[
                                { label: "Target Distance", key: "bio.targetDistance", suffix: " km", format: "number" },
                            ]}
                        />
                    </Col>

                    <Col col={{ xs: 16 }}>
                        <ReportMetaTable
                            data={data}
                            columnSize={{ xs: 8 }}
                            columnA={[
                                { label: "Target Time", key: "bio.targetTime" },
                            ]}
                            columnB={[
                                { label: "Target Speed", key: "bio.targetSpeed", suffix: " kph", format: "number" },
                            ]}
                            columnC={[
                                { label: "Target Power", key: "bio.targetPower", suffix: " W", format: "number" },
                            ]}
                        />
                    </Col>
                </Row>

            </Col>

        </Row>

    ), [data]);

};

export default SessionsReportRiderInformation;