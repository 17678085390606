import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Space } from '@dex/bubl-dash';
import { loop, parseNum, titleCase } from '@dex/bubl-helpers';
import styles from "./TestSessionsReport.module.scss";
import SessionReportPage from './SessionReportPage';
import TestSessionsReportMenu from '../../TestSessions/Report/TestSessionsReportMenu';
import SessionReportCover from './SessionReportCover';
import SessionsReportRiderInformation from './SessionsReportRiderInformation';
import SessionsReportComment from './SessionsReportComment';
import SessionsReportAerodynamic from './SessionsReportAerodynamic';

const SessionReportPages: React.FC<any> = (props: any) => {

    const { data, form } = props;

    const [activePage, setActivePage] = useState(0);

    const observer: any = useRef();

    const getPages = () => {

        let pages: any = [];

        let all: any = [
            {
                key: "0.cover",
                label: "Cover",
                heading: false,
                el: SessionReportCover,
                ref: useRef()
            },
            {
                key: "1.rider",
                label: "Rider Information",
                heading: "Rider Information",
                el: SessionsReportRiderInformation,
                ref: useRef()
            },
            {
                key: "aerodynamic",
                label: "Aerodynamic",
                heading: "Aerodynamic Parameter",
                el: SessionsReportAerodynamic,
                ref: useRef()
            },
            {
                key: "positions",
                label: "Your Training This Week",
                heading: "Your Training This Week",
                el: "SessionReportTrainingWeek",
                ref: useRef()
            },
            {
                key: "speedAndPower",
                label: "Your Training This Week",
                heading: "Your Training This Week",
                el: "SessionReportTrainingWeek",
                ref: useRef()
            },
            {
                key: "summary",
                label: "Fitter's comments",
                heading: "Fitter's comments",
                el: SessionsReportComment,
                ref: useRef()
            },
        ].filter((page) => {

            if (!data.reportPages || !data.reportPages.length) return true;

            return data.reportPages.includes(page.key) ? true : false;

        });

        let pageBikeAerodynamic: any;
        let pageBikePositions: any;
        let pageBikeSpeedAndPower: any;
        let pageBikeSummary: any;

        loop(all, (page, index) => {

            if (page.key === "aerodynamic") return pageBikeAerodynamic = page;
            if (page.key === "positions") return pageBikePositions = page;
            if (page.key === "speedAndPower") return pageBikeSpeedAndPower = page;
            if (page.key === "summary") return pageBikeSummary = page;

            pages.push(page);

        });

        loop(data.report.bikes, (index) => {

            if (!pageBikeAerodynamic && !pageBikePositions && !pageBikeSpeedAndPower && !pageBikeSummary) return;

            const bike = data.bikes[index];

            if (!bike) return;

            pages.push({
                sep: true,
                label: "Bikes - " + bike.brand,
            });

            [pageBikeAerodynamic, pageBikePositions, pageBikeSpeedAndPower, pageBikeSummary].map((page) => {

                if (!page) return;

                pages.push({
                    ...page,
                    label: page.label,
                    bike: bike,
                    index: index,
                    ref: useRef(),
                });

            });

        });

        return pages;

    };

    const pages = getPages();

    useEffect(() => {

        const callback = (entries, observer) => {

            let inViewTarget: any = null;
            let inViewRatio = 0;

            entries.forEach((entry, index) => {

                if (entry.isIntersecting && entry.intersectionRatio > inViewRatio) {

                    inViewRatio = entry.intersectionRatio;
                    inViewTarget = entry.target;

                }

            })

            if (inViewTarget) setActivePage(parseNum(inViewTarget.id));

        };

        const observer = new IntersectionObserver(callback, {
            threshold: .4,
        });

        pages.map((page) => {

            if (page.ref && page.ref.current) {

                observer.observe(page.ref.current);

            }

        });

        () => {

            pages.map((page) => {

                observer.unobserve(page.ref.current);

            });

            observer.disconnect();

        }

    }, []);

    const handleScrollTo = useCallback((ref, event) => {

        if (!ref || !ref.current) return;

        if (event && event.preventDefault) event.preventDefault();

        ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });

    }, []);

    return useMemo(() => (

        <>

            <TestSessionsReportMenu
                data={data}
                activePage={activePage}
                pages={pages}
                handleScrollTo={handleScrollTo}
                form={form}
            />

            {pages.map((page, index) => (

                <React.Fragment key={index}>

                    {index >= 0 && page.el &&
                        <Space />
                    }

                    {page.el &&

                        <SessionReportPage
                            heading={page.heading}
                            icon={page.icon}
                            index={index}
                            observer={observer}
                            forwardedRef={page.ref}
                            setActivePage={setActivePage}
                            form={form}
                            data={data}
                        >

                            {page.el &&
                                <page.el
                                    data={data}
                                    form={form}
                                    index={page.index}
                                    bike={page.bike}
                                />
                            }

                        </SessionReportPage>
                    }

                </React.Fragment>

            ))}

        </>

    ), [data, activePage]);

}

export default SessionReportPages;