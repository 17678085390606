import React from 'react';
import ReportDesc from '../../../elements/ReportDesc/ReportDesc';
import { Col, FieldNumber, FieldSelect, FieldText, Row, Space } from '@dex/bubl-dash';
import CoachingReportHeading from '../../../elements/CoachingReportHeading/CoachingReportHeading';
import { get } from '@dex/bubl-helpers';
import { airDensityCalculation, airPressureCalculation, gravityCalculation } from '../../../functions/sharedAerodynamic';

const SessionsReportAerodynamic: React.FC<any> = (props: any) => {

    const { data, form, bike, index } = props;

    const bikeForm = get(form.values, `bikes[${index}]`);
    const prefix = `bikes[${index}]`;

    console.log(bikeForm, prefix)
    return (
        <Row gutter={8} edge>

            <Col col={{ xs: 12 }}>

                <CoachingReportHeading
                    text="Enter A Value"
                />

                <Row gutter={8} edge>

                    <Col col={{ xs: 11 }}>
                        <FieldNumber
                            form={form}
                            name={`${prefix}.parameter.bikeSpeed`}
                            defaultValue={bikeForm.parameter?.bikeSpeed}
                            label='Speed'
                            suffix=" kph"
                            wrapClass="manual-input inline-label"
                        />
                    </Col>


                    <Col col={{ xs: 2 }} align='center' justify='center'>
                        or
                    </Col>

                    <Col col={{ xs: 11 }}>
                        <FieldNumber
                            form={form}
                            name={`${prefix}.parameter.power`}
                            defaultValue={bikeForm.parameter?.power}
                            label='Power'
                            suffix=" W"
                            wrapClass="manual-input inline-label"
                        />
                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 12 }}>

                <Space />

                <ReportDesc
                    label=""
                    value={bike?.summary?.aerodynamicParameter || ""}
                />
            </Col>

            <Col col={{ xs: 12 }}>

                <CoachingReportHeading
                    text={"Environment"}
                />

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 19 }}>

                        <Space />

                        <FieldNumber
                            form={form}
                            label="Air Temperature"
                            name={`${prefix}.parameter.airTemperature`}
                            defaultValue={bike.parameter?.airTemperature}
                            wrapClass="manual-input inline-label label-align-left"
                            suffix=" C"
                            dynamicValue
                        />

                    </Col>

                    <Col col={{ xs: 19 }}>

                        <FieldNumber
                            form={form}
                            label="Relative Humidity"
                            name={`${prefix}.parameter.relativeHumidity`}
                            defaultValue={bike.parameter?.relativeHumidity}
                            wrapClass="manual-input inline-label label-align-left"
                            suffix=" %"
                            dynamicValue
                        />

                    </Col>

                    <Col col={{ xs: 19 }}>

                        <FieldNumber
                            form={form}
                            label="Altitude"
                            name={`${prefix}.parameter.altitude`}
                            defaultValue={bike.parameter?.altitude}
                            wrapClass="manual-input inline-label label-align-left"
                            suffix=" m"
                        />

                    </Col>

                    <Col col={{ xs: 19 }}>

                        <FieldNumber
                            form={form}
                            label="% Grade"
                            name={`${prefix}.parameter.percentGrade`}
                            defaultValue={bike.parameter?.percentGrade}
                            wrapClass="manual-input inline-label label-align-left"
                            suffix=" %"
                        />

                    </Col>

                    <Col col={{ xs: 19 }}>

                        <FieldNumber
                            form={form}
                            label="Headwind"
                            name={`${prefix}.parameter.headwind`}
                            defaultValue={bike.parameter?.headwind}
                            wrapClass="manual-input inline-label label-align-left"
                            suffix=" kph"
                        />

                    </Col>

                    <Col col={{ xs: 19 }}>

                        <FieldNumber
                            form={form}
                            label="Yaw Angle"
                            name={`${prefix}.parameter.yawAngle`}
                            defaultValue={bike.parameter?.yawAngle}
                            wrapClass="manual-input inline-label label-align-left"
                            suffix=" deg"
                        />

                    </Col>

                    <Col col={{ xs: 19 }}>

                        <Space />

                        <FieldSelect
                            form={form}
                            label="Road Type"
                            name={`${prefix}.parameter.roadType`}
                            defaultValue={bike.parameter?.roadType}
                            wrapClass="manual-input inline-label label-align-left"
                            options={[
                                { label: "Track", value: "Track" },
                                { label: "Road", value: "Road" },
                                { label: "Off-Road", value: "Off-Road" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 5 }}>

                        <FieldText
                            form={form}
                            //@ts-ignore
                            label={<span>C<sub>rr</sub> Adjustment</span>}
                            name={`${prefix}.parameter.roadTypeCrr`}
                            defaultValue={bike.parameter?.roadTypeCrr}
                            wrapClass={"green-input"}
                            dynamicValue
                        />

                    </Col>

                    <Col col={{ xs: 19 }}>

                        <FieldSelect
                            form={form}
                            label="Road Surface"
                            name={`${prefix}.parameter.roadSurface`}
                            defaultValue={bike.parameter?.roadSurface}
                            wrapClass="manual-input inline-label label-align-left"
                            options={[
                                bike.parameter?.roadType == "Track" ? { label: "Wood", value: "Wood" } : null,
                                bike.parameter?.roadType == "Track" || bike.parameter?.roadType == "Road" ? { label: "Smooth Concreate", value: "Smooth Concreate" } : null,
                                bike.parameter?.roadType == "Track" ? { label: "Smooth Asphalt", value: "Smooth Asphalt" } : null,
                                bike.parameter?.roadType == "Off-Road" || bike.parameter?.roadType == "Road" ? { label: "Rough Asphalt", value: "Rough Asphalt" } : null,
                                bike.parameter?.roadType == "Off-Road" || bike.parameter?.roadType == "Road" ? { label: "Mixed", value: "Mixed" } : null,
                                bike.parameter?.roadType == "Off-Road" || bike.parameter?.roadType == "Road" ? { label: "Gravel", value: "Gravel" } : null,
                                bike.parameter?.roadType == "Off-Road" || bike.parameter?.roadType == "Road" ? { label: "Cobblestones/Brick", value: "Cobblestones/Brick" } : null,
                                bike.parameter?.roadType == "Off-Road" || bike.parameter?.roadType == "Road" ? { label: "Uneven Dirt", value: "Uneven Dirt" } : null,
                            ].filter((option): option is { label: string; value: string } => option !== null)}
                        />

                    </Col>

                    <Col col={{ xs: 5 }}>

                        <FieldText
                            form={form}
                            label={""}
                            name={`${prefix}.parameter.roadSurfaceCrr`}
                            defaultValue={bike.parameter?.roadSurfaceCrr}
                            wrapClass={"green-input"}
                            dynamicValue
                        />

                    </Col>

                    <Col col={{ xs: 19 }}>

                        <FieldNumber
                            form={form}
                            label="Gravitational Acceleration"
                            name={`${prefix}.parameter.gravitationalAcceleration`}
                            defaultValue={bike.parameter?.gravitationalAcceleration}
                            wrapClass=" inline-label label-align-left"
                            disabled
                            suffix=" m/sec²"
                            dynamicValue
                        />

                    </Col>

                    <Col col={{ xs: 16 }}>

                        <Space />

                        <span style={{ fontWeight: 600 }}>
                            Air Pressure = {airPressureCalculation(bike.parameter?.altitude, bike.parameter?.airTemperature, bike.parameter?.airPressureUnit)}
                        </span>

                        <br />

                        <span style={{ fontWeight: 600 }}>
                            Air Density = {airDensityCalculation(bike.parameter?.relativeHumidity, bike.parameter?.airTemperature, bike.parameter?.altitude)}
                        </span>

                        <br />

                        <span style={{ fontWeight: 600 }}>
                            F<sub>G</sub> = {gravityCalculation(bike.parameter?.percentGrade, data.bio?.weight, bike.parameter?.BikeWeight)} N
                        </span>

                    </Col>

                    <Col col={{ xs: 8 }}>

                        <FieldSelect
                            form={form}
                            label=""
                            name={`${prefix}.parameter.airPressureUnit`}
                            defaultValue={bike.parameter?.airPressureUnit}
                            wrapClass=" inline-label label-align-left"
                            options={[
                                { label: "atm", value: "atm" },
                                { label: "mmHg", value: "mmHg" },
                                { label: "inHg", value: "inHg" },
                                { label: "kPa", value: "kPa" },
                                { label: "Pa", value: "Pa" },
                                { label: "psi", value: "psi" },
                            ]}
                        />

                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 12 }}>

                <CoachingReportHeading
                    text={"Bike"}
                />

            </Col>

        </Row>
    )
}

export default SessionsReportAerodynamic;