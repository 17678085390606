import { Col, Row, Space } from '@dex/bubl-dash';
import React, { useMemo } from 'react';
import styles from "./GaitReportAnalysis.module.scss";
import { ColoredHeader } from './GaitReportPage';
import GaitReportComment from '../../../elements/GaitReportComment/GaitReportComment';
import CoachingReportHeading from '../../../elements/CoachingReportHeading/CoachingReportHeading';
import { get, isEmpty } from '@dex/bubl-helpers';
import GaitRatingCard from '../../../elements/GaitRatingCard/GaitRatingCard';

const GaitReportAnalysis: React.FC<any> = (props: any) => {

    const { data, form, run, index } = props;

    const runData = run;
    const gender = data.athlete.gender;

    return useMemo(() => (

        <Row edge gutter={20}>

            <Col col={{ xs: 24 }}>

                <ColoredHeader
                    heading="Running Analysis"
                    desc={`${runData.date}`}
                />

            </Col>

            <Col col={{ xs: 24 }}>

                <CoachingReportHeading
                    text="Foot Strike (Initial)"
                    legend={<RunningAnalysisLegend />}
                />

            </Col>

            <Col col={{ xs: 8 }} >

                <GaitReportAnalysisChild
                    data={runData}
                    title="Foot Inclination"
                    optimal="Minimal"
                    commentKey="side.footInclination.comment"
                    valueRight={get(runData, "side.footInclination.rightRating")}
                    valueLeft={get(runData, "side.footInclination.leftRating")}
                    image="FootInclination"
                />

            </Col>

            <Col col={{ xs: 8 }} className='table-bdr-left-gray'>

                <GaitReportAnalysisChild
                    data={runData}
                    title="Leg Inclination"
                    optimal="5 Deg Or Less"
                    commentKey="side.legInclination.comment"
                    valueLeft={get(runData, "side.legInclination.leftRating")}
                    valueRight={get(runData, "side.legInclination.rightRating")}
                    image="LegInclination"
                />

            </Col>

            <Col col={{ xs: 8 }} className='table-bdr-left-gray'>

                <GaitReportAnalysisChild
                    data={runData}
                    title="Heel Strike Orientation"
                    optimal="Slightly Supinated To Neutral"
                    commentKey="rear.heelStrikeOrientation.comment"
                    valueRight={get(runData, "rear.heelStrikeOrientation.rightRating")}
                    valueLeft={get(runData, "rear.heelStrikeOrientation.leftRating")}
                    image="HeelStrikeOrientation"
                    className={"centeredColor"}
                />

            </Col>

            <Col col={{ xs: 8 }} >

                <GaitReportAnalysisChild
                    data={runData}
                    title="Foot Pronation Rate"
                    optimal="Moderate"
                    commentKey="rear.footPronationRate.comment"
                    valueLeft={get(runData, "rear.footPronationRate.leftRating")}
                    valueRight={get(runData, "rear.footPronationRate.rightRating")}
                    image="FootPronationRate"
                />

            </Col>

            <Col col={{ xs: 8 }} className='table-bdr-left-gray'>

                <GaitReportAnalysisChild
                    data={runData}
                    title="Foot Direction"
                    optimal="Straight"
                    commentKey="rear.footDirection.comment"
                    valueLeft={get(runData, "rear.footDirection.leftRating")}
                    valueRight={get(runData, "rear.footDirection.rightRating")}
                    image="FootDirection"
                    className={"centeredColor"}
                />

            </Col>

            <Col col={{ xs: 8 }} className='table-bdr-left-gray'>

                <GaitReportAnalysisChild
                    data={runData}
                    title="Foot Strike Audio"
                    optimal="None To Mild"
                    commentKey="side.footStrikeAudio.comment"
                    value={get(runData, "side.footStrikeAudio.rating")}
                    image="FootStrikeAudio"
                />

            </Col>

        </Row>

    ), [data]);

}

export const GaitReportAnalysisChild: React.FC<any> = (props: any) => {

    const { data, title, optimal, commentKey, image, valueLeft, valueRight, value, gender, className } = props;

    return (
        <Row edge gutter={8}>

            <Col col={{ xs: 16 }}>
                <p className='sub-heading-title'>{title}</p>
                <strong >(Optimal : {optimal})</strong>
            </Col>

            <Col col={{ xs: 8 }}>

                <div className={styles.image}>
                    {image &&
                        <img
                            src={require(`../../../assets/images/Gait/icon-${image}.png`)}
                            alt={image}
                        />
                    }
                </div>

            </Col>

            <Col col={{ xs: 24 }}>
                <GaitRatingCard
                    title={value ? "" : "Left"}
                    metric={title}
                    value={!isEmpty(valueLeft) ? valueLeft : value}
                    gender={gender}
                    split={!value ? {
                        metric: title,
                        value: valueRight,
                        title: "Right"
                    } : null}
                    type={image}
                    className={className}
                />
            </Col>

            {!valueLeft &&
                <Space height={102} />
            }

            <Col col={{ xs: 24 }}>
                <GaitReportComment
                    value={get(data, commentKey)}
                />
            </Col>

        </Row>
    )
}

export const RunningAnalysisLegend: React.FC<any> = (props: any) => {

    return (
        <Row edge gutter={15}>

            <Col>
                <div className={styles.legendContainer}>
                    <div className={styles.normal} />
                    <p>Normal/Optimal</p>
                </div>
            </Col>

            <Col>
                <div className={styles.legendContainer}>
                    <div className={styles.mild} />
                    <p>Mild Issue</p>
                </div>
            </Col>

            <Col>
                <div className={styles.legendContainer}>
                    <div className={styles.major} />
                    <p>Major Issue</p>
                </div>
            </Col>

        </Row >

    )
}

export default GaitReportAnalysis;