import React, { useEffect } from 'react';
import { Bar, BarChart, Cell, Label, LabelList, Legend, ReferenceLine } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';
import { fixedNum, loop } from '@dex/bubl-helpers';
import { getClockTime, getDecimalTime, getDuration } from '../../functions/sharedTests';
import { fancyHourFormat, fancyTimeFormat } from '../../functions/sharedChart';

const ChartPerformanceTimeTrial: React.FC<ChartPerformanceTimeTrialProps> = (props) => {

    let { data, type, compareTo, parameter, setTimeTrialDistance } = props;

    useEffect(() => {
        setTimeTrialDistance(type)
    }, [type]);

    const Axis = new ChartAxis();

    Axis.x = {
        key: "ridingPosition",
        label: "Bike Speed - kph",
        index: 6,
        format: undefined
    }

    Axis.x.props = {
        interval: 0,
        type: "category",
        ticks: data?.map(item => item.ridingPosition),
    }

    const totalSecondsToHMSTime = (totalSeconds) => {
        const isNegative = totalSeconds < 0;
        totalSeconds = Math.abs(totalSeconds);

        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = Math.floor(totalSeconds % 60);

        // If the time was negative, adjust the sign
        const formattedTime = `${isNegative ? '-' : ''}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

        return formattedTime;
    };


    const calculateTotalTIme = (distance, speed) => {

        if (!distance || !speed) return 0;

        const sec: number = (distance / speed) * 3600;

        const clockTime = totalSecondsToHMSTime(sec)

        return sec
    };

    loop(data, (item, index) => {

        item.trialTime = calculateTotalTIme(type, item.speed);

        const timeDiff = item?.trialTime - data[(compareTo - 1)]?.trialTime;

        item.timeDiff = timeDiff;

        const initialValue = data[(compareTo - 1)]?.trialTime;
        if (initialValue !== 0) {
            const percentageDiff = (timeDiff / initialValue) * 100;
            item.timeDiffPercent = fixedNum(percentageDiff, 0);
        } else {
            item.timeDiffPercent = 0;
        }
    });

    if (parameter === "Total Time") {
        Axis.y.push({
            axis: true,
            // bar: true,
            pos: "left",
            index: 4,
            key: "trialTime",
            axisLabel: "Total Time - hh:mm:ss",
            label: "Total Time",
            props: {
                tickFormatter: fancyTimeFormat
            }
        });
    } else if (parameter === "Avg Speed") {
        Axis.y.push({
            axis: true,
            // bar: true,
            pos: "left",
            index: 1,
            key: "speed",
            axisLabel: "Avg Speed",
            label: "Avg Speed",
            props: {
            }
        });
    } else if (parameter === "Avg Power") {
        Axis.y.push({
            axis: true,
            // bar: true,
            pos: "left",
            index: 0,
            key: "power",
            axisLabel: "Avg Power",
            label: "Avg Power",
            props: {
            }
        });
    }

    Axis.legend = false;
    console.log(Axis)
    return (

        <Chart>

            <BarChart
                {...ChartProps.BarChart()}
                data={data}
            >

                {Axis.render()}

                {Axis.y.length >= 1 &&
                    <Bar
                        dataKey={Axis.y[0].key || ""}
                        yAxisId={"left-axis"}
                        barSize={60}
                        fill={ChartProps.indexColor(Axis.y[0].index)}
                    >
                        {data.map((item, index) => (
                            <>
                                <LabelList position="insideBottom" offset={20} fill={ChartProps.indexColor(7)} formatter={totalSecondsToHMSTime} dataKey={'timeDiff'} />
                                <LabelList position="insideBottom" fill={ChartProps.indexColor(7)} formatter={(val) => `(${val} %)`} dataKey={'timeDiffPercent'} />
                            </>
                        ))}
                    </Bar>
                }

            </BarChart>

        </Chart>


    )

}

interface ChartPerformanceTimeTrialProps {
    [key: string]: any,
}

export default ChartPerformanceTimeTrial;