import React, { useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import { Button, Space, useMountEffect } from '@dex/bubl-dash';

const SessionsEditTabsBikeSummary: React.FC<any> = (props: any) => {

    const { form, prefix, bike, data, setKey, useDefaultText } = props;

    useMountEffect(() => {
        if (!bike.summary?.aerodynamicParameter) form.handleChange({ reset: true, name: `${prefix}.summary.aerodynamicParameter`, value: "Select different parameters to see how they affect your Aerodynamic drag, power output and Time Trial durations in the different positions." })
        if (!bike.summary?.performanceChart) form.handleChange({ reset: true, name: `${prefix}.summary.performanceChart`, value: "Your CdA (Aerodynamic drag or resistance) and estimated power across the different riding positions are shown for the targeted speed." })
        if (!bike.summary?.timeTrialChart) form.handleChange({ reset: true, name: `${prefix}.summary.timeTrialChart`, value: "Time to complete different time trial distances are shown in this chart for the targeted speed or power selected." })
        if (!bike.summary?.powerSpeedChart) form.handleChange({
            reset: true, name: `${prefix}.summary.powerSpeedChart`,
            value: `Your Power values across a range of speeds are shown above for each position.Also given are the Power differences between positions as well as the % of your FTP (threshold) value.By definition, the time possible to hold 100 % of FTP power is about an hour(~30 - 45km).So, trying to ride at a greater power than your FTP for durations over 60 minutes is likely not possible.The % FTP can be used as a reference for determining how best to pace yourself for a given cycling distance.`
        })
    });

    return useMemo(() => (

        <Row gutter={8} edge={true}>


            <Col col={{ xs: 24, lg: 20 }}>

                <FieldTextArea
                    form={form}
                    label={"Summary"}
                    name={`${prefix}.summary.summary`}
                    defaultValue={bike.summary?.summary}
                    rows={10}
                    maxLength={8000}
                />

            </Col>

            <Col col={{ xs: 24, lg: 4 }}>

                <Space />

                <Button
                    label="Use Default Text"
                    type='faded'
                    size='small'
                    confirm={bike.summary?.summary ? { title: 'Are you sure?', message: 'Are you sure you want to overwrite the current comment' } : undefined}
                    onClick={useDefaultText.bind(null, "aerodynamicSummary", `${prefix}.summary.summary`)}
                />

            </Col>

            <Col col={{ xs: 24, lg: 20 }}>

                <FieldTextArea
                    form={form}
                    label={"Report - Aerodynamic Parameters Text"}
                    name={`${prefix}.summary.aerodynamicParameter`}
                    defaultValue={bike.summary?.aerodynamicParameter}
                    rows={4}
                    maxLength={500}
                />

            </Col>

            <Col col={{ xs: 24, lg: 20 }}>

                <FieldTextArea
                    form={form}
                    label={"Report - Performance Chart Text"}
                    name={`${prefix}.summary.performanceChart`}
                    defaultValue={bike.summary?.performanceChart}
                    rows={4}
                    maxLength={500}
                />

            </Col>

            <Col col={{ xs: 24, lg: 20 }}>

                <FieldTextArea
                    form={form}
                    label={"Report - Time Trial Chart Text"}
                    name={`${prefix}.summary.timeTrialChart`}
                    defaultValue={bike.summary?.timeTrialChart}
                    rows={4}
                    maxLength={500}
                />

            </Col>

            <Col col={{ xs: 24, lg: 20 }}>

                <FieldTextArea
                    form={form}
                    label={"Report - Power-Speed Chart Text"}
                    name={`${prefix}.summary.powerSpeedChart`}
                    defaultValue={bike.summary?.powerSpeedChart}
                    rows={4}
                    maxLength={500}
                />

            </Col>

        </Row>

    ), [props]);

}

export default SessionsEditTabsBikeSummary;
