import React, { useCallback, useMemo } from 'react';
import { Form, useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Col, Row } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldCheckbox from '@dex/bubl-dash/src/lib/components/Fields/FieldCheckbox';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { ButtonRow } from '@dex/bubl-dash';
import SessionsEditTabsPanel from './SessionsEditTabsPanel';
import { bikeLabel } from '../../../functions/sharedBikeFit';

const SessionsEditTabsReport: React.FC<any> = (props: any) => {

    const { data, handleSubmit, form, process } = props;

    const app = useApp();

    const bikeValues: any = [];
    const pageValues: any = [];
    const subBikeOptions: any = [

    ];
    const bikeOptions = data.bikes.map((bike, i) => {

        bikeValues.push(i + "");

        return { value: i + "", label: "Bike " + (i + 1) + " - " + bikeLabel(bike) };

    });


    let pageOptions = [
        { value: "0.cover", label: "Cover" },
        { value: "1.rider", label: "Rider Information" },
        { value: "2.posture", label: "Posture" },
        { value: "3.stability", label: "Stability, Mobility & Symmetry" },
        { value: "4.cleat", label: "Shoe & Cleat Alignment" },
        { value: "5.bikes", label: "Bikes" },
        { value: "6.summary", label: "Summary" },
        { value: "7.exercises", label: "Exercises" },
    ];

    if (data.fitType === 'Aerodynamic') {

        pageOptions = [
            { value: "0.cover", label: "Cover" },
            { value: "1.rider", label: "Rider Information" },
            { value: "aerodynamic", label: "Aerodynamic Parameter" },
            { value: "positions", label: "Positions" },
            { value: "speedAndPower", label: "Speed And Power" },
            { value: "summary", label: "Fitter's comments" },
        ];

    }
    pageOptions.map((option, i) => {

        pageValues.push(option.value);

        return option;

    });


    const path = window.location.origin;

    const copyLink = useCallback((link) => {

        navigator.clipboard.writeText(link);

        app.alert('Link Copied To Clipboard', 'info');

    }, [data]);

    return useMemo(() => (

        <SessionsEditTabsPanel
            heading={"Report"}
            {...props}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>

                        <ButtonRow>

                            <Button
                                label={"View Report"}
                                path={`${path}/session/report/${form.values?.reportId}`}
                                target={"_blank"}
                                size={"small"}
                                type={"primary"}
                            />

                            <Button
                                label={"Copy Link"}
                                onClick={copyLink.bind(null, `${path}/session/report/${form.values?.reportId}`)}
                                size={"small"}
                                type={"light"}
                            />

                        </ButtonRow>

                    </Col>

                    <Col col={{ xs: 24, sm: 24 }}>

                        <FieldCheckbox
                            list={true}
                            form={form}
                            label={"Pages"}
                            name='report.pages'
                            defaultValue={form.defaults.report?.pages}
                            required={true}
                            options={pageOptions}
                        />

                    </Col>

                    {((form.values.report?.pages?.includes("aerodynamic")) || (form.values.report?.pages?.includes("positions")) ||
                        (form.values.report?.pages?.includes("speedAndPower")) || (form.values.report?.pages?.includes("summary"))) &&
                        <Col col={{ xs: 24, sm: 24 }}>

                            <FieldCheckbox
                                list={true}
                                form={form}
                                label={"Bikes"}
                                name='report.bikes'
                                defaultValue={form.defaults.report?.bikes}
                                required={true}
                                options={bikeOptions}
                            />

                        </Col>
                    }

                </Row>

            </Form>

        </SessionsEditTabsPanel>

    ), [props, form.hash, bikeOptions, pageOptions]);

}

export default SessionsEditTabsReport;