import React, { useEffect, useMemo, useState } from 'react';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import ChartPowerVariation from '../../../elements/ChartBikeAerodynamic/ChartPowerVariation';
import { Col, FieldCheckbox, FieldSelect, Heading, InputTable, Row, WhiteSpace } from '@dex/bubl-dash';
import { fixedNum, get, loop, slug, unslug } from '@dex/bubl-helpers';
import { calculatePowerWithDrive } from '../../../functions/sharedAerodynamicBike';
import styles from "./BikeAerodynamic.module.scss";
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';

const SessionsEditTabsBikePowerChart: React.FC<any> = (props: any) => {

    const { form, prefix, bike, data } = props;

    const types = bike?.positions?.map((position, i) => ({
        label: "Position " + (i + 1),
        value: "Position " + (i + 1),
    }));

    const [powerChartData, setPowerChartData] = useState<any[]>([]);

    useEffect(() => {

        loop(bike.positions, (position, index) => {
            if (!position.comparePosition) form.handleChange({ reset: true, name: `${prefix}.positions[${index}].comparePosition`, value: `Position 1` });
        });

        const newPowerChartData = Array.from({ length: 60 / 5 + 1 }, () => ({}));

        loop(bike?.positions, (position, index) => {
            const FTPPower = data.bio.ftpW;
            const comparePosition = removeWhitespace(position?.comparePosition);

            for (let i = 0; i <= 60; i += 5) {
                const dragResistance = get(form.values, `${prefix}.positions[${index}].totalDragResistance`);
                const power = calculatePowerWithDrive(i, bike.parameter?.headwind, dragResistance, bike.parameter?.driveChangePowerLoss);
                const percentFTP = (power / FTPPower) * 100;

                const dataIndex = i / 5;
                newPowerChartData[dataIndex][`speed`] = i;
                newPowerChartData[dataIndex][`powerPosition${index + 1}`] = fixedNum(power, 0);
                newPowerChartData[dataIndex][`percentFTPPosition${index + 1}`] = fixedNum(percentFTP, 0);


            }
        })

        loop(newPowerChartData, (chart, i) => {

            loop(bike?.positions, (position, index) => {

                const comparePosition = removeWhitespace(position?.comparePosition);

                //difference
                const power = chart[`powerPosition${index + 1}`];
                const percentFTP = chart[`percentFTPPosition${index + 1}`];

                newPowerChartData[i][`differencePercentFTPPosition${index + 1}`] = percentFTP - chart[`percentFTP${comparePosition}`];
                newPowerChartData[i][`differencePowerPosition${index + 1}`] = power - chart[`power${comparePosition}`];
            })

        })

        form.handleChange({ reset: true, name: `${prefix}.powerChartData`, value: newPowerChartData });

        setPowerChartData(newPowerChartData);

    }, [form.hash]);

    const removeWhitespace = (text) => {
        return text.replace(/\s+/g, "");
    };

    return (

        <Row edge gutter={8}>

            <Col col={{ xs: 24 }}>

                <PanelChart
                    key={form.hash}
                    heading={"Power Variation At Different Speeds"}
                    data={powerChartData}
                    renderForm={(form, heading) => (

                        <Row edge gutter={8}>

                            <Col col={{ xs: 16 }}>
                                <h2 style={{ color: "white" }}>
                                    {heading}
                                </h2>
                            </Col>

                            <Col >
                                <FieldCheckbox
                                    key={form.defaults.powerVariationPosition}
                                    form={form}
                                    name={"powerVariationPosition"}
                                    defaultValue={form.defaults.powerVariationPosition}
                                    options={types}
                                />
                            </Col>

                        </Row>

                    )}
                    formDefaults={{ powerVariationPosition: types ? [types[0].value] : [] }}
                    renderChart={(chartType, chartData, values) => (
                        <ChartPowerVariation
                            data={chartData}
                            type={chartType}
                            allData={bike}
                            filter={values}
                        />
                    )}
                />

            </Col>

            {bike?.positions?.map((position, index) => {
                return (
                    <Col col={{ xs: 24 / bike?.positions.length }} className={styles.positionTable} key={index}>

                        <Heading className={"text-center"} space={3}>Position {index + 1}</Heading>
                        <Heading className={[styles["text-blue"], "text-center"].join(" ")} space={3}>{position.photoCaption || <WhiteSpace />}</Heading>

                        <Row gutter={8} edge>

                            <Col col={{ xs: index >= 1 ? 5 : 8 }}>
                                <WhiteSpace />
                            </Col>

                            <Col col={{ xs: index >= 1 ? 5 : 8 }}>
                                <WhiteSpace />
                            </Col>

                            <Col col={{ xs: index >= 1 ? 5 : 8 }}>
                                &nbsp;
                            </Col>

                            {index == 0 ? null : (
                                <Col col={{ xs: 9 }} className='text-center'>
                                    Difference
                                </Col>
                            )}

                            <Col col={{ xs: index >= 1 ? 5 : 8 }} className={[styles["text-light-blue"], 'text-center'].join(" ")}>
                                KPH
                                <Row gutter={8} edge>
                                    {powerChartData?.map((data, i) => (
                                        <Col col={{ xs: 24 }} key={i}>
                                            {data.speed}
                                        </Col>
                                    ))}
                                </Row>

                            </Col>

                            <Col col={{ xs: index >= 1 ? 5 : 8 }} className={[styles["text-secondary"], 'text-center'].join(" ")}>
                                W
                                <Row gutter={8} edge>
                                    {powerChartData?.map((data, i) => (
                                        <Col col={{ xs: 24 }} key={i}>
                                            {get(data, `powerPosition${index + 1}`)}
                                        </Col>
                                    ))}
                                </Row>

                            </Col>

                            <Col col={{ xs: index >= 1 ? 4 : 8 }} className='text-center'>
                                %FTP
                                <Row gutter={8} edge>
                                    {powerChartData?.map((data, i) => (
                                        <Col col={{ xs: 24 }} key={i}>
                                            {get(data, `percentFTPPosition${index + 1}`)} %
                                        </Col>
                                    ))}
                                </Row>

                            </Col>

                            {index == 0 ? null : (
                                <Col col={{ xs: 5 }} className={[styles["text-purple"], 'text-center'].join(" ")}>
                                    W
                                    <Row gutter={8} edge>
                                        {powerChartData?.map((chartData, i) => {
                                            return (
                                                <Col col={{ xs: 24 }} key={i}>
                                                    {get(chartData, `differencePowerPosition${index + 1}`)} %
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Col>
                            )}

                            {index == 0 ? null : (
                                <Col col={{ xs: 5 }} className={[styles["text-dark4"], 'text-center'].join(" ")}>
                                    %FTP
                                    <Row gutter={8} edge>
                                        {powerChartData?.map((chartData, i) => (
                                            <Col col={{ xs: 24 }} key={i}>
                                                {get(chartData, `differencePercentFTPPosition${index + 1}`)} %
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                            )}

                        </Row>

                    </Col>
                )
            })}

            {bike?.positions?.map((position, index) => {
                return (
                    <Col col={{ xs: 24 / bike?.positions.length }} key={index}>

                        {index >= 1 &&
                            <FieldSelect
                                form={form}
                                label="compare To"
                                name={`${prefix}.positions[${index}].comparePosition`}
                                defaultValue={position.comparePosition}
                                options={types}
                                wrapClass="manual-input inline-label"
                            />
                        }

                    </Col>
                )
            })}

        </Row>

    );

}

export default SessionsEditTabsBikePowerChart;
