import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import { FieldAutocomplete, FieldUpload, Heading, Line, useUpdateEffect } from '@dex/bubl-dash';
import { FSACalculation, meterSquareCalculation } from '../../../functions/sharedAerodynamic';

const SessionsEditTabsBikeDetails: React.FC<any> = (props: any) => {

    const { form, prefix, bike, data } = props;

    const handleBarOptions = useCallback((bikeType) => {

        if (bikeType === "Road") {

            return [
                { value: "Dropbars", label: "Dropbars" },
                { value: "Aerobars", label: "Aerobars" },
                { value: "None", label: "None" },
            ]

        } else if (bikeType === "Time Trial") {

            return [
                { value: "Aerobars", label: "Aerobars" },
                { value: "None", label: "None" },
            ]

        } else if (bikeType === "MTB" || bikeType === "Foldie") {

            return [
                { value: "MTB-Riser", label: "MTB-Riser" },
                { value: "MTB-Straight", label: "MTB-Straight" },
                { value: "MTB-Upsweep", label: "MTB-Upsweep" },
                { value: "MTB-Other", label: "MTB-Other" },
                { value: "None", label: "None" },
            ];

        } else {

            return [
                { value: "Dropbars", label: "Dropbars" },
                { value: "None", label: "None" },
            ];

        }

    }, []);

    useEffect(() => {

        if (bike.type) {

            const options = handleBarOptions(bike.type);

            let hasValidOption = false;

            options.map(option => {

                if (option.value === bike.handlebarType) hasValidOption = true;

            });

            if (hasValidOption) return;

            form.handleChange({ name: `${prefix}.handlebarType`, value: options[0].value });

        }

    }, [bike.type]);

    useUpdateEffect(() => {

        form.handleChange({ reset: true, name: `${prefix}.squarePerPixel`, value: meterSquareCalculation(bike.calibrationDistance, bike.calibrationPixel) });
        form.handleChange({ reset: true, name: `${prefix}.FSA`, value: FSACalculation(bike.imagePixel, bike.squarePerPixel) });

    }, [form.hash]);
    return useMemo(() => (

        <Row gutter={8} edge={true}>

            <Col col={{ xs: 24, md: 18 }}>

                <FieldRadio
                    button={true}
                    form={form}
                    label={"Type"}
                    name={`${prefix}.type`}
                    defaultValue={bike.type}
                    options={[
                        { value: "Road", label: "Road" },
                        { value: "Road-Aero", label: "Road-Aero" },
                        { value: "Road-Aerobars", label: "Road-Aerobars" },
                        { value: "Time Trial", label: "Time Trial" },
                        { value: "Gravel", label: "Gravel" },
                        { value: "MTB", label: "MTB" },
                    ]}
                />

            </Col>

            <Col col={{ xs: 24, md: 6 }}>

                <FieldSelect
                    key={bike.type}
                    button={true}
                    form={form}
                    label={"Handlebar Type"}
                    name={`${prefix}.handlebarType`}
                    defaultValue={bike.handlebarType}
                    options={handleBarOptions(bike.type)}
                />

            </Col>

            <Col col={{ xs: 12, md: 6 }}>

                <FieldAutocomplete
                    form={form}
                    required={true}
                    label={"Bike Brand"}
                    name={`${prefix}.brand`}
                    defaultValue={bike.brand}
                    group={"BikeBrand"}
                    preload={true}
                />

            </Col>

            <Col col={{ xs: 12, md: 6 }}>

                <FieldAutocomplete
                    key={bike.brand}
                    form={form}
                    label={"Bike Model"}
                    name={`${prefix}.model`}
                    defaultValue={bike.model}
                    group={"BikeModel-" + bike.brand}
                    preload={true}
                />

            </Col>

            <Col col={{ xs: 12, md: 6 }}>

                <FieldNumber
                    form={form}
                    label={"Year"}
                    name={`${prefix}.year`}
                    defaultValue={bike.year}
                    thousandSeparator=""
                    max={2999}
                />

            </Col>

            <Col col={{ xs: 12, md: 6 }}>

                <FieldText
                    form={form}
                    label={"Bike Size"}
                    name={`${prefix}.setupBikeSize`}
                    defaultValue={bike.setupBikeSize}
                />

            </Col>

            <Col col={{ xs: 24 }}>
                <Heading>
                    Bike-Rider Frontal Surface Area (FSA) - Standing
                    <Line />
                </Heading>
            </Col>

            <Col col={{ xs: 24, md: 12 }}>

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24 }}>
                        <FieldNumber
                            form={form}
                            label={"Image Pixels"}
                            name={`${prefix}.imagePixel`}
                            defaultValue={bike.imagePixel}
                            wrapClass={"manual-input inline-label label-align-left"}
                        />
                    </Col>

                    <Col col={{ xs: 24 }}>
                        <FieldNumber
                            form={form}
                            label={"Calibration Pixels"}
                            name={`${prefix}.calibrationPixel`}
                            defaultValue={bike.calibrationPixel}
                            wrapClass={"manual-input inline-label label-align-left"}
                        />
                    </Col>

                    <Col col={{ xs: 24 }}>
                        <FieldNumber
                            form={form}
                            label={"Calibration Distance"}
                            name={`${prefix}.calibrationDistance`}
                            defaultValue={bike.calibrationDistance}
                            wrapClass={"manual-input inline-label label-align-left "}
                            suffix=" m"
                        />
                    </Col>

                    <Col col={{ xs: 24 }}>
                        <FieldNumber
                            form={form}
                            label={"m² per pixels"}
                            name={`${prefix}.squarePerPixel`}
                            defaultValue={bike.squarePerPixel}
                            wrapClass={"manual-input inline-label label-align-left "}
                            suffix=" m²"
                            disabled
                            dynamicValue
                        />
                    </Col>

                    <Col col={{ xs: 24 }}>
                        <FieldNumber
                            form={form}
                            label={"Measured Bike-Rider FSA"}
                            name={`${prefix}.FSA`}
                            defaultValue={bike.FSA}
                            wrapClass={"green-input inline-label label-align-left "}
                            suffix=" m²"
                            dynamicValue
                        />
                    </Col>

                </Row>

            </Col>

            <Col col={{ xs: 24, md: 12 }}>

                <FieldUpload
                    form={form}
                    name={`${prefix}.FSAPhoto`}
                    folder='fit size-media'
                    label='FSA Photo'
                    defaultValue={bike.FSAPhoto}
                    multiple={false}
                    accept='image/*'
                />

            </Col>

        </Row>


    ), [props]);

}

export default SessionsEditTabsBikeDetails;
