import React, { useEffect, useMemo, useState } from 'react';
import PanelChart from '../../../elements/PanelChart/PanelChart';
import { Col, FieldRadio, FieldSelect, Row, useMountEffect } from '@dex/bubl-dash';
import ChartPerformanceMetric from '../../../elements/ChartBikeAerodynamic/ChartPerformanceMetric';
import { get } from '@dex/bubl-helpers';
import ChartProps from '../../../elements/Chart/Chart.Props';
import ChartPerformanceTimeTrial from '../../../elements/ChartBikeAerodynamic/ChartPerformanceTimeTrial';

const SessionsEditTabsBikePerformance: React.FC<any> = (props: any) => {

    const { form, prefix, bike, data } = props;

    useMountEffect(() => {

        if (!bike.performance?.metricChartType) form.handleChange({ reset: true, name: `${prefix}.performance.metricChartType`, value: "Power" })
        if (!bike.performance?.metricCompareTo) form.handleChange({ reset: true, name: `${prefix}.performance.metricCompareTo`, value: 1 })
        if (!bike.performance?.timeTrialCompareTo) form.handleChange({ reset: true, name: `${prefix}.performance.timeTrialCompareTo`, value: 1 })
        if (!bike.performance?.parameter) form.handleChange({ reset: true, name: `${prefix}.performance.parameter`, value: "Total Time" })

    });

    const [timeTrialDistance, setTimeTrialDistance] = useState("10");
    let timeTrialText: string = "";

    switch (timeTrialDistance) {
        case "10":
            timeTrialText = `• 10k TT’s are commonly ridden at 95%-105%% of FTP for Novice and between 105-115% for very fit Cyclists.`
            break;
        case "40":
            timeTrialText = `• 40k TT’s are commonly ridden at 80%-85% of FTP for Novice and between 87% to 102% for very fit Cyclists.`
            break;
        case "90":
            timeTrialText = `• 90k TT’s are commonly ridden at 75%-80% of FTP for Novice and between 79% to 84% for very fit Cyclists.`
            break;
        case "180":
            timeTrialText = `• 180k TT’s are commonly ridden at 65%-70% of FTP for Novice and between 75% to 80% for very fit Cyclists. `
            break;
        default:
            timeTrialText = `• 10k TT’s are commonly ridden at 95%-105%% of FTP for Novice and between 105-115% for very fit Cyclists.`
            break;
    }
    return (

        <Row gutter={8} edge={true}>

            <Col col={{ xs: 20 }}>

                <PanelChart
                    key={form.hash}
                    heading={"Performance Metrics By Position"}
                    data={bike.positions}
                    renderChart={(chartType, chartData) => {

                        return (
                            <ChartPerformanceMetric
                                data={chartData}
                                type={bike.performance?.metricChartType}
                                metricCompareTo={bike.performance?.metricCompareTo}
                                FTPPower={data.bio.ftpW}
                            />
                        )
                    }
                    }
                />

            </Col>

            <Col col={{ xs: 4 }}>

                <FieldRadio
                    form={form}
                    name={`${prefix}.performance.metricChartType`}
                    defaultValue={bike.performance?.metricChartType}
                    list
                    label='Metric'
                    key={bike.performance?.metricChartType}
                    options={[
                        {
                            label: "Power",
                            value: "Power",
                            color: ChartProps.performanceMetricColor("Power")
                        },
                        {
                            label: "Speed",
                            value: "Speed",
                            color: ChartProps.performanceMetricColor("Speed")
                        },
                        {
                            label: "CdA",
                            value: "CdA",
                            color: ChartProps.performanceMetricColor("CdA")
                        },
                        {
                            label: "W Diff",
                            value: "W Diff",
                            color: ChartProps.performanceMetricColor("W Diff")
                        },
                        {
                            label: "% FTP",
                            value: "% FTP",
                            color: ChartProps.performanceMetricColor("% FTP")
                        },
                    ]}
                />

                {bike.performance?.metricChartType == "W Diff" &&
                    <FieldSelect
                        form={form}
                        name={`${prefix}.performance.metricCompareTo`}
                        defaultValue={bike.performance?.metricCompareTo}
                        label='Compare To'
                        options={bike.positions.map((item, index) => ({ label: "Position " + (index + 1), value: (index + 1) })) || []}
                        wrapClass="manual-input"
                        key={bike.performance?.metricCompareTo}
                    />
                }

            </Col>

            <Col col={{ xs: 20 }}>

                <PanelChart
                    key={form.hash}
                    heading={"Time Trial Performance By Position"}
                    data={bike.positions}
                    types={[
                        {
                            name: "10",
                            label: "10km",
                        },
                        {
                            name: "40",
                            label: "40km",
                        },
                        {
                            name: "90",
                            label: "90km",
                        },
                        {
                            name: "180",
                            label: "180km",
                        },
                    ]}
                    renderChart={(chartType, chartData) => {
                        return (
                            <ChartPerformanceTimeTrial
                                type={chartType}
                                compareTo={bike.performance?.timeTrialCompareTo}
                                parameter={bike.performance?.parameter}
                                data={bike.positions}
                                FTPPower={data.bio.ftpW}
                                setTimeTrialDistance={setTimeTrialDistance}
                            />
                        )
                    }
                    }
                />

                <p>{timeTrialText}</p>

            </Col>

            <Col col={{ xs: 4 }}>

                <FieldRadio
                    form={form}
                    name={`${prefix}.performance.parameter`}
                    defaultValue={bike.performance?.parameter}
                    list
                    key={bike.performance?.parameter}
                    options={[
                        {
                            label: "Total Time",
                            value: "Total Time",
                            color: ChartProps.indexColor(4)
                        },
                        {
                            label: "Avg Speed",
                            value: "Avg Speed",
                            color: ChartProps.indexColor(1)
                        },
                        {
                            label: "Avg Power",
                            value: "Avg Power",
                            color: ChartProps.indexColor(0)
                        }
                    ]}
                />

                <FieldSelect
                    form={form}
                    name={`${prefix}.performance.timeTrialCompareTo`}
                    defaultValue={bike.performance?.timeTrialCompareTo}
                    label='Compare To'
                    options={bike?.positions?.map((item, index) => ({ label: "Position " + (index + 1), value: (index + 1) })) || []}
                    wrapClass="manual-input"
                />

            </Col>

        </Row>

    );

}

export default SessionsEditTabsBikePerformance;
