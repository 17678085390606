import React from 'react';
import { Label, LineChart, ReferenceLine } from 'recharts';
import Chart from '../Chart/Chart';
import ChartProps from '../Chart/Chart.Props';
import ChartAxis from '../Chart/ChartAxis';

const ChartPowerVariation: React.FC<ChartPowerVariationProps> = (props) => {

    let { data, allData, filter } = props;

    const Axis = new ChartAxis();

    Axis.grid = {
        vertical: true,
    };

    Axis.x = {
        key: "speed",
        index: 6,
        label: "Speed (kph)",
        props: {
            interval: 0,
            domain: [0, 80],
            // type: "number",
            // ticks: kph
        }
    };

    Axis.y = [
        {
            line: true,
            pos: "left",
            index: 0,
            key: "powerPosition1",
            label: "Position 1",
            axisLabel: "Power - W",
            props: {}
        },
        {
            line: true,
            pos: "left",
            index: 1,
            key: "powerPosition2",
            label: "Position 2",
            axisLabel: "Power - W",
            props: {}
        },
        {
            line: true,
            pos: "left",
            index: 2,
            key: "powerPosition3",
            label: "Position 3",
            axisLabel: "Power - W",
            props: {}
        }
    ];

    Axis.y = Axis.y.filter(item => filter?.powerVariationPosition?.length > 0 && filter?.powerVariationPosition.includes(item.label));

    Axis.y = Axis.y.map((item, index) => ({
        ...item,
        axis: index === 0
    }))

    const power = allData.parameter?.power;
    const speed = allData.parameter?.bikeSpeed;
    const useTarget = allData.parameter?.useTarget;

    return (

        <Chart>

            <LineChart
                {...ChartProps.LineChart()}
                data={data}
            >

                {Axis.render()}

                {useTarget == "power" && filter.length > 0 &&
                    <ReferenceLine
                        x={power}
                        stroke={ChartProps.indexColor(0)}
                        yAxisId={"left-axis"}
                        strokeDasharray="3"
                    >
                        <Label
                            value={"Target Power : " + power + " W"}
                            position='top'
                            fill={ChartProps.indexColor(0)}
                            stroke={ChartProps.indexColor(0)}
                            offset={20}
                        />
                    </ReferenceLine>
                }

                {useTarget == "speed" && filter.length > 0 &&
                    <ReferenceLine
                        x={speed}
                        stroke={ChartProps.indexColor(1)}
                        yAxisId={"left-axis"}
                        strokeDasharray="3"
                    >
                        <Label
                            value={"Target Speed : " + speed + " kph"}
                            position='top'
                            fill={ChartProps.indexColor(1)}
                            stroke={ChartProps.indexColor(1)}
                            offset={20}
                        />
                    </ReferenceLine>
                }

            </LineChart>

        </Chart>


    )

}

interface ChartPowerVariationProps {
    [key: string]: any,
}

export default ChartPowerVariation;